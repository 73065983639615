<template>
  <div style="display: flex">
    <click-to-edit
      v-model="localValue"
      :disabled="!isFieldAvailableForEdit(scope.row, 'scale.template_id')"
      style="flex: 1 1 auto; margin-right: 5px; overflow: hidden; overflow-wrap: anywhere"
      type="select"
      select-filterable
      :select-options="scaleTemplates"
      select-option-id="id"
      select-option-name="name"
      select-option-empty-name-text="Нет названия"
      select-cast-value-to-int
      @opened="remoteMethod"
      @input="saveElementPropChange(scope.row, 'scale', {template_id:$event})"
    >
    </click-to-edit>

    <el-popover
      style="margin-top:2px;"
      placement="top"
      title="Описание"
      width="400"
      trigger="click"
    >
      <el-button
        slot="reference"

        class="table-action-button open-desc-button"
        :class="{'has-desc': !!description}"
        type="text"
        round
        size="mini"
        icon="fas fa-info-circle"
      ></el-button>
      <div style="white-space: pre-line">{{ description ? description : 'Нет описания для шкалы' }}</div>
    </el-popover>


  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import requestSender from "@/api/base/requestSender";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
import CardElementConfigurableScaleEditDialog
  from "@/components/configurableScales/settings/CardElementConfigurableScaleEditDialog.vue";

export default {
  name: 'element-configurable-scale-table-column',
  components: {CardElementConfigurableScaleEditDialog, ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  computed: {
    description() {
      return this.scope.row?.scale?.template?.description;
    },
    scopesEditDisabled() {
      if (!this.isFieldAvailableForEdit(this.scope.row, 'scale.scopes')) {
        return true;
      }
      if (!this.scope.row?.scale?.template?.allow_scopes_edit_in_element) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      localValue: null,
      scaleTemplates: [],
    }
  },
  watch: {
    'scope.row.scale.template': function (newVal) {
      this.scaleTemplates = [];
      this.scaleTemplates.push(this.scope.row.scale.template);
      this.localValue = this.scope.row.scale.template.id;
    }
  },
  mounted() {
    if (this.scope.row.scale.template) {
      this.scaleTemplates.push(this.scope.row.scale.template);
      this.localValue = this.scope.row.scale.template.id;
    }
  },
  methods: {
    remoteMethod() {
      requestSender('get', 'scale/templates-list', {
        sort: 'name',
      })
        .then(data => {
          this.scaleTemplates = data.scales;
        })
    },
  }
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";

.open-desc-button {
  color: gray;

  &.has-desc {
    color: $--color-primary;
  }
}
</style>
